import React, {useRef} from 'react';

import {EffectCoverflow, Navigation} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import styled from 'styled-components';
import {useUiStore} from "../store/userStore";
import SwiperButtonPrev from "./SiperButtonPrev";
import SwiperButtonNext from "./SwiperButtonNext";
import {motion} from "framer-motion";

const RankList: React.FC = () => {
    const ranks = useUiStore((state) => state.ranks);
    const currentRank = useUiStore((state) => state.currentRank);
    const swiperRef = useRef<any>(null); // Создаем реф для доступа к Swiper

    const currentRankToDisplay = currentRank
    const rankName = ranks[currentRank].name

    const handleSwipeRight = () => {
    }
    const handleSwipeLeft = () => {

    }

    return (
        <RankContainer initial={{ opacity: 0 }} // Элемент изначально ниже экрана
                       animate={{ opacity: 1 }} // При анимации элемент перемещается в центр
                       exit={{ opacity: 0 }} // При выходе элемент уезжает обратно вниз
                       transition={{duration: 0.3, // Длительность анимации
                       }}>
            <Swiper
                onSlideNextTransitionStart={handleSwipeRight} // Свайп вправо
                onSlidePrevTransitionStart={handleSwipeLeft}  // Свайп влево
                spaceBetween={5}
                slidesPerView={3}
                centeredSlides
                modules={[Navigation, EffectCoverflow]}
                initialSlide={currentRankToDisplay}
                ref={swiperRef}
                effect="coverflow"
                coverflowEffect={{
                    modifier: 1.5,
                    rotate: 0,
                    slideShadows: false,
                    scale: 0.9,
                    stretch: 0,
                    depth: 350

                }}
            >
                {ranks.map((rank) => (
                    <SwiperSlide key={rank.name}>
                        <RankItem currentRank={currentRankToDisplay} isActive={rank.name === rankName}>
                            <img src={rank.image} alt=""/>
                        </RankItem>
                    </SwiperSlide>
                ))}
                <SwiperButtonNext/>
                <SwiperButtonPrev/>
            </Swiper>

        </RankContainer>
    );
};

const RankContainer = styled(motion.div)`
    padding: 0 10px;
    width: 100%;
    margin-top: 10vh;
    //height: 115px;
`;

const RankItem = styled.div<{ currentRank: number, isActive: boolean }>`
    transition: background 0.3s, box-shadow 0.3s;
    filter: ${props => props.isActive ? 'none' : 'brightness(0.5) grayscale(0.9)'};

    .swiper-slide-active img {
        opacity: 1;
        filter: grayscale(0);
    }


    & img {
        width: 100%;
        filter: ${props => props.isActive ? 'grayscale(0)' : ' grayscale(0.9)'};
            //opacity: ${props => props.isActive ? '1' : '0.5'};
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 60px;
            height: 60px;
            background: rgba(105, 255, 147, 0.5);
            border-radius: 50%;
            transform: translate(-50%, -50%);
            opacity: ${props => props.isActive ? 1 : 0};
            filter: blur(10px);
            transition: opacity 0.3s;
            z-index: -1;
        }

`;


export default RankList;

