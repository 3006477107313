import { create } from 'zustand';

// Определим возможные статусы ответа
type ResponseStatus = 'have_answer' | 'in_progress' | 'complete' | 'received_reward' | 'no_questions' | 'wrong_question' | null;

export interface Question  {
    id: number,
    time_sent:string,
    u_text: string,
    answer: string,
    status: ResponseStatus,
    user_id: number
    secret: boolean
}

// Типы состояния
interface ChatState {
    //states
    questionsHistory: Question[]; // История сообщений от ИИ
    lastQuestionDate: Date | null; // Дата последнего вопроса
    userQuestion: string; // Текущий вопрос
    aiAnswer: string; // Ответ ИИ
    questionStatus: ResponseStatus; // Статус ответа ИИ

    //actions
    addMessage: (question: Question) => void; // Функция для добавления сообщения
    setUserQuestion: (question: string) => void; // Функция для установки текущего вопроса
    setLastQuestionDate: (date: Date) => void; // Функция для установки даты последнего вопроса
    setQuestionStatus: (status: ResponseStatus) => void; // Функция для установки статуса ответа
    setAiAnswer: (answer: string) => void; // Функция для установки ответа ИИ
    setQuestionsHistory: (questionsHistory: Question[]) => void;
}

// Создание zustand store
export const useChatStore = create<ChatState>((set) => ({
    questionsHistory: [],
    lastQuestionDate: null,
    userQuestion: '',
    aiAnswer: '',
    questionStatus: null, // Изначальный статус — complete
    addMessage: (question) => set((state) => ({ questionsHistory: [...state.questionsHistory, question] })),
    setUserQuestion: (question) => set({ userQuestion: question }),
    setLastQuestionDate: (date) => set({ lastQuestionDate: date }),
    setQuestionStatus: (status) => set({ questionStatus: status }), // Обновление статуса ответа
    setAiAnswer: (answer) => set({ aiAnswer: answer }), // Обновление ответа ИИ
    setQuestionsHistory: (questionsHistory) => set({ questionsHistory}), // Обновление ответа ИИ
}));
