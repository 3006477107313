import { useCallback, useMemo } from 'react';
import { useRewardStore } from '../store/rewardStore';

export const useRewardPopup = () => {
    const rewardsToClaim = useRewardStore((state) => state.rewardsToClaim);
    const claimRewards = useRewardStore((state) => state.claimRewards);

    const isRewardPopupVisible = useMemo(() => rewardsToClaim.length > 0, [rewardsToClaim]);

    const popupRewards = useMemo(() => rewardsToClaim, [rewardsToClaim]);

    const handleClosePopup = useCallback(() => {
        claimRewards();
    }, [claimRewards]);

    return {
        isRewardPopupVisible,
        popupRewards,
        handleClosePopup
    };
};
