import {create} from 'zustand';
import axios from 'axios';
import {useEffect} from 'react';

// Интерфейсы для состояния и данных
interface Country {
    value: string;
    label: string;
}

interface State {
    country: Country | null;
    countries: Country[];
    flag: string; // Новое свойство для флага
    fetchCountries: () => void;
    setCountry: (country: Country) => void;
    setCountryByValue: (countryCode: string) => void;
}

// Функция для парсинга флага из строки label
const parseFlagFromLabel = (label: string): string => {
    return label.split(' ')[0]; // Разделяем строку по пробелу и берем первый элемент (эмодзи)
};

// Создаем zustand store
export const useCountryStore = create<State>((set, get) => ({
    country: {label: '', value: ''},
    countries: [],
    flag: '', // По умолчанию флаг пустой

    // Функция для фетча данных
    fetchCountries: async () => {
        try {
            const response = await axios.get(
                'https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code'
            );
            const {countries} = response.data;

            set({
                countries: countries,
            });
        } catch (error) {
            console.error("Ошибка при фетче стран:", error);
        }
    },

    // Функция для обновления выбранной страны
    setCountry: (country: Country) => {
        const flag = parseFlagFromLabel(country.label); // Парсим флаг при смене страны
        set({country, flag});
    },
    setCountryByValue: async (countryCode: string) => {
        await get().fetchCountries()
        const { countries } = get();

        // Обрезаем код страны до первых двух символов

        // Находим страну по очищенному коду
        const selectedCountry = countries.find((country) => country.value === countryCode);

        if (selectedCountry) {
            const flag = parseFlagFromLabel(selectedCountry.label);  // Извлекаем флаг
            set({
                country: selectedCountry,
                flag: flag
            });
        }
    },
}));


