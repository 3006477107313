import axios from 'axios';
import {initDataStore, User} from "../store/initDataStore";
import {handleApiError} from "../utils/handleApiError";
import {Reward} from "../store/rewardStore";
import {LeaderboardResponse} from "../store/leaderbordStore";
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

console.log("API_URL", API_URL);

if (!API_URL) {
    throw new Error('API_URL is not defined in environment variables');
}

const getHeaders = () => {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
    }
}

export const getUserProfile = async () => {
    const response = await axios.get(`${API_URL}/user/me`, {headers: getHeaders()});

    

    return response.data;
};

export const startMining = async () => {
    try {
        const response = await axios.post(`${API_URL}/mining/start`, {}, {headers: getHeaders()});
        console.log('Успешный старт майнинга:', response.data);
        return response.data;
    } catch (error: any) {
        handleApiError(error)
    }
};

export const claimMining = async () => {
    try {
        const response = await axios.post(`${API_URL}/mining/claim`, {}, {headers: getHeaders()});
        console.log('Успешный клейм майнинга:', response.data);
        return response.data;
    } catch (error: any) {
        handleApiError(error)
    }
};

export const promoteRank = async () => {
    try {
        const response = await axios.patch(`${API_URL}/ranks/promote`, {}, {headers: getHeaders()});

        console.log('Успешный promote:', response.data);
        toast.success('Ранк успешно повышен!');
        return response.data;
    } catch (error: any) {
        handleApiError(error);
        toast.error('Ошибка при повышении ранка!');
        throw error;
    }
};

export const getRewardsList = async (): Promise<Reward[]> => {
    const { data } = await axios.get(`${API_URL}/rewards/available`, { headers: getHeaders() });

    return data
};

export const claimReward = async (rewardIds: number[]) => {
    try {
        const response = await axios.post(
            `${API_URL}/rewards/activate`, 
            { reward_ids: rewardIds },
            { headers: getHeaders() }
        );
        
        return response.data;
    } catch (error: any) {
        handleApiError(error)
        throw error;
    }
};

// export const claimRewards = async () => {
//     // Получаем актуальное состояние из хранилища
//     const headers = getHeaders()
//
//     console.log(headers);
//
//     try {
//         const response = await axios.post(`${API_URL}/reward/receive/all`, {},
//             {headers: headers}
//         );
//
//     } catch (error: any) {
//         handleApiError(error)
//     }
// };

export const getLeaderboard = async (): Promise<LeaderboardResponse> => {
    const response = await axios.get<LeaderboardResponse>(`${API_URL}/leaderboard/referrals`, { headers: getHeaders() });

    const responseData = response.data 

    return responseData;
};

export const getPastLeaderboard = async (): Promise<[LeaderboardResponse | null, Error | null]> => {
    try {
        const response = await axios.get(`${API_URL}/user/leaderboard/past`, {headers: getHeaders()});

        if (response.data) {
            console.log(response.data)
        }

        const responseData = response.data.body
            ? JSON.parse(response.data.body)
            : response.data

        return [responseData, null]
    } catch (error: any) {
        handleApiError(error)
        return [null, error]
    }
};

export const getQuestionsHistory = async () => {
    try {
        const response = await axios.get(`${API_URL}/questions/history`, {headers: getHeaders()});

        if (response.data) {
            const responseData = response.data.body
                ? JSON.parse(response.data.body).data.questions
                : response.data.data.questions;
            return responseData
        }


    } catch (error: any) {
        handleApiError(error)
    }
};

export interface QuestionStatus {
    status: 'have_answer' | 'in_progress' | 'complete' | 'received_reward' | 'no_questions' | 'wrong_question' | null
    u_text?: string;
    answer?: string;
    created_at?: string;
    completed_at?: string;
}

export const getQuestionStatus = async (): Promise<QuestionStatus> => {
    try {
        const response = await axios.get(`${API_URL}/questions/last_question`, {headers: getHeaders()});

        const responseData = response.data.body
            ? JSON.parse(response.data.body).data
            : response.data.data;

        return responseData;
    } catch (error: any) {
        handleApiError(error)
        return { status: null };
    }
};

export const sendQuestion = async (question: string) => {
    const response = await axios.post(
        `${API_URL}/questions/ask?question=${encodeURIComponent(question)}`,
        {},
        {headers: getHeaders()}
    );

    return response.data;
};

export const getTasks = async () => {
    try {
        const response = await axios.get(`${API_URL}/tasks/available`, {headers: getHeaders()});

        const responseData = response.data.body
            ? JSON.parse(response.data.body).tasks
            : response.data.tasks;

        return responseData
    } catch (error: any) {
        handleApiError(error)
    }
};

export const startTaskApi = async (task_id: number) => {
    try {
        const response = await axios.post(`${API_URL}/tasks/${task_id}/start`, {},
            {headers: getHeaders()}
        );

        console.log('Su claim rewards:', response.data);
    } catch (error: any) {
        handleApiError(error)
    }
}

export const completeTask = async (task_id: number) => {
    console.log(getHeaders());
    try {
        const response = await axios.post(`${API_URL}/tasks/${task_id}/complete`, {},
            {headers: getHeaders()}
        );
        return response.data

        console.log('Su claim rewards:', response.data);
    } catch (error: any) {
        handleApiError(error)
    }
}

export const changeRegion = async (country: string) => {
    try {
        const response = await axios.post(
            `${API_URL}/user/change_region`,
            {code: country},
            {headers: getHeaders()}
        );

        return response.data;
    } catch (error: any) {
        handleApiError(error)
    }
};

export const authenticateUser = async (initData: string) => {
    const response = await fetch(`${API_URL}/auth/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ init_data: initData }),
    });

    if (!response.ok) {
        throw new Error('Authentication failed');
    }

    const data = await response.json();
    console.log('data', data);
    // Сохраняем токен в localStorage
    localStorage.setItem('auth_token', data.access_token);
    return data;
};

let isRefreshing = false; // Флаг для отслеживания состояния повторной аутентификации
let failedQueue: any[] = []; // Очередь для хранения запросов, которые должны быть повторены после обновления токена

const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

// Добавляем перехватчик для обработки ошибок 401
axios.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return axios(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                const initData = "query_id=AAGdJCdOAgAAAJ0kJ04qzMwI&user=%7B%22id%22%3A36229259932231%2C%22first_name%22%3A%22Anna%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22sobored19%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1728123438&hash=5020a03cfd02dd7635c74449e271041b3f5091c8792a4755d83fc42800826b8f";
                const data = await authenticateUser(initData);
                localStorage.setItem('auth_token', data.access_token);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
                processQueue(null, data.access_token);
                return axios(originalRequest);
            } catch (authError) {
                processQueue(authError, null);
                window.location.href = '/login';
                return Promise.reject(authError);
            } finally {
                isRefreshing = false;
            }
        }

        return Promise.reject(error);
    }
);

export const getReferralInfo = async () => {
    try {
        const response = await axios.get(`${API_URL}/referal/info`, { headers: getHeaders() });
        return response.data;
    } catch (error: any) {
        handleApiError(error);
        throw error;
    }
};


