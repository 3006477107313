import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { getRewardsList, getUserProfile } from '../api/api';
import { Reward, useRewardStore } from '../store/rewardStore';
import { useUiStore } from '../store/userStore';
import { useRankDisplayStore } from '../store/rankDisplayStore';
import Popup from '../components/Popup';
import Loader from '../components/helpers/Loader';

const SecureRoute: React.FC = () => {
    const navigate = useNavigate();
    const { setRewards, setRewardsToClaim, claimRewards } = useRewardStore();
    const { setUserName, ranks, setCurrentRank } = useUiStore();
    const { setCurrentRankToDisplay } = useRankDisplayStore();
    const [showRewardsPopup, setShowRewardsPopup] = useState(false);
    const [dailyRewards, setDailyRewards] = useState<Reward[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchRewards = async () => {
            try {
                const rewardsData = await getRewardsList();
                setRewards(rewardsData);

                const dailyRewards = rewardsData.filter(reward =>
                    reward.type === 'DAILY_BONUS' && !reward.is_activated
                );

                if (dailyRewards.length > 0) {
                    setDailyRewards(dailyRewards);
                    setShowRewardsPopup(true);
                }

                setIsLoading(false);
            } catch (error) {
                console.error("Ошибка при загрузке наград:", error);
                setIsLoading(false);
            }
        };

        const fetchUserProfile = async () => {
            try {
                const userProfile = await getUserProfile();
                console.log("userProfile", userProfile);
                setUserName(userProfile.username);

                const userRankName = userProfile.rank.toLowerCase();
                console.log("userRankName", userRankName);
                const rankIndex = ranks.findIndex(rank => rank.name.toLowerCase() === userRankName);
                console.log("rankIndex", rankIndex);
                if (rankIndex !== -1) {
                    setCurrentRank(rankIndex);
                    setCurrentRankToDisplay(rankIndex);
                }
            } catch (error) {
                console.error("Ошибка при загрузке профиля пользователя:", error);
            }
        };

        const token = localStorage.getItem('auth_token');
        if (!token) {
            navigate('/login');
            return;
        }

        fetchUserProfile();
        fetchRewards();
    }, [navigate, setRewards, setUserName, ranks, setCurrentRank, setCurrentRankToDisplay]);

    const handlePopupClose = async () => {
        await claimRewards();
        setShowRewardsPopup(false);
    };

    if (isLoading) {
        return <Loader statusMessage="Loading rewards..." />;
    }

    return (
        <>
            <Outlet />
            {showRewardsPopup && (
                <Popup
                    header="Daily Bonus!"
                    subTitle="You've earned your daily reward"
                    rewards={dailyRewards}
                    onClose={handlePopupClose}
                />
            )}
        </>
    );
};

export default SecureRoute;
