import React from 'react';
import ControlPanel from "../components/ControlPanel";
import Avatar from "../components/Avatar";
import Footer from "../components/Footer";
import styled from "styled-components";
import defaultBackground from "../assets/images/bg.png";

interface LayoutProps {
    children: React.ReactNode;
    background?: string; // Пропс для фона теперь необязателен
    hideAvatar?: boolean; // Новый пропс для скрытия аватара
}

const Layout: React.FC<LayoutProps> = ({ children, background, hideAvatar }) => {
    return (
        <>
            <Container background={background || defaultBackground}>
                <ControlPanelWrapper>
                    <ControlPanel />
                </ControlPanelWrapper>
                {!hideAvatar && (
                    <AvatarWrapper>
                        <Avatar />
                    </AvatarWrapper>
                )}
                <ContentWrapper>
                    {children} {/* Уникальный контент каждой страницы */}
                </ContentWrapper>
                <FooterWrapper>
                    <Footer />
                </FooterWrapper>
            </Container>
        </>
    );
};

const Container = styled.div<{ background?: string }>`
    height: 100vh; /* Высота страницы фиксирована */
    display: flex;
    flex-direction: column;
    width: 100vw; /* Изменено на 100vw для устранения отступов */
    background-image: ${(props) => props.background ? `url(${props.background})` : 'none'};
    background-position: 50% 4vh;
    overflow: hidden; /* Убираем скроллинг */
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0; /* Убираем паддинги */
`;

const ControlPanelWrapper = styled.div`
    flex: 0 0 auto; /* Фиксированная высота */
`;

const AvatarWrapper = styled.div`
    flex: 0 0 auto; /* Фиксированная высота */
`;

const ContentWrapper = styled.div`
    flex: 1 1 auto; /* Занимает оставшееся пространство */
    overflow: hidden; /* Убираем скроллинг */
`;

const FooterWrapper = styled.div`
    flex: 0 0 auto; /* Фиксированная высота */
    margin-left: 0; /* Убираем паддинги контейнера */
    margin-right: 0; /* Убираем паддинги контейнера */
`;

export default Layout;
