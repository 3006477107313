import React from 'react';
import styled, {keyframes} from "styled-components";
import {colors} from "../../styles/styles";

const Spinner = () => {
    return (
        <SpinnerContainer/>
    );
};

const SpinnerA = keyframes`
    to {
        transform: rotate(1turn);
    }
`

const SpinnerContainer = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 5px solid #dbdcef;
    border-right-color: ${colors.mainColor};
    animation: ${SpinnerA} 1s infinite linear;
`

export default Spinner;