import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticateUser } from '../api/api';
import { colors, CtaButton } from '../styles/styles';
import styled from 'styled-components';
import logoIcon from '../assets/icons/logo.svg';

const LoginPage: React.FC = () => {
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Очистка токена при заходе на страницу логина
        localStorage.removeItem('auth_token');
    }, []);

    const handleLogin = async () => {
        try {
            const initData = "query_id=AAGdJCdOAgAAAJ0kJ04qzMwI&user=%7B%22id%22%3A362292259932231%2C%22first_name%22%3A%22Anna%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22sobored19%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1728123438&hash=5020a03cfd02dd7635c74449e271041b3f5091c8792a4755d83fc42800826b8f";
            await authenticateUser(initData);
            navigate('/'); // Перенаправление на страницу "Home"
        } catch (err: any) {
            setError('Authentication error: ' + err.message);
        }
    };

    return (
        <FullScreenContainer>
            <FormContainer>
                <WelcomeText>Welcome To The Temple</WelcomeText>
                <LogoContainer>
                    <Logo src={logoIcon} alt="Logo" />
                    <LogoText>OVERSEER</LogoText>
                </LogoContainer>
                <MATermsText>Acceptance of MA Terms</MATermsText>
                <MATermsDescription>
                    By using the MAF, you agree that you have read in full, understood and accepted to be legally bound by the terms contained herein, in addition to <a href="https://telegram.org/tos"><strong>Telegram's Terms of Service</strong></a>,
                    <a href="https://telegram.org/privacy"><strong>Telegram's Privacy Policy</strong></a> and the respective Terms of Service of each MA you access.
                </MATermsDescription>
                {/* <HCaptcha
                    sitekey={hcaptchaSiteKey}
                    onVerify={handleCaptchaChange}
                    languageOverride="en"
                    theme="dark"
                    size="normal"
                />  */}
                <ButtonContainer>
                    <StyledCtaButton onClick={handleLogin}>Login</StyledCtaButton>
                    {error && <ErrorText>{error}</ErrorText>}
                </ButtonContainer>
            </FormContainer>
        </FullScreenContainer>
    );
};

const FullScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: ${colors.background};
  padding: 0 10px;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const WelcomeText = styled.div`
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 10px;
  margin-top: 82px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 38px;
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const LogoText = styled.span`
  font-family: 'K2D';
  font-style: normal;
  font-weight: 700;
  font-size: 24.6209px;
  line-height: 120%;
  leading-trim: both;
  text-edge: cap;
  text-align: center;
  letter-spacing: 0.39em;
  text-transform: uppercase;
  background: linear-gradient(270deg, #EFEBBA 9.88%, #C9A351 131.46%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const MATermsText = styled.div`
  font-family: 'K2D';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 114px;
`;

const MATermsDescription = styled.div`
  font-family: 'K2D';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  // display: flex;
  align-items: center;
  color: ${colors.secondaryText};
  margin-bottom: 20px;

  color: #808080;

  a {
    color: ${colors.activeText};
    text-decoration: none;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 52px;
  margin-top: 90px;
`;

const StyledCtaButton = styled(CtaButton)`
  width: 100%;
  height: 52px;
`;

const ErrorText = styled.p`
  color: red;
  margin-top: 10px;
`;

export default LoginPage; 