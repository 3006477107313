import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';

const ConfettiAnimation = () => {
    useEffect(() => {
        // Функция для запуска конфетти
        const fireConfetti = () => {
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 },
            });
        };

        fireConfetti();
    }, []);

    return null; // Компонент не рендерит ничего
};

export default ConfettiAnimation;
