import React, {useEffect} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {AppContainer} from "./styles/styles";
import GlobalStyles from "./styles/globalStyles";
import RanksPage from "./pages/RanksPage";
import HomePage from "./pages/HomePage";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AiGodPage from "./pages/AiGodPage";
import NotFoundPage from "./pages/NotFoundPage";
import TasksPage from "./pages/TasksPage";
import LeaderboardPage from "./pages/LeaderboardPage";
import FrensPage from "./pages/FrensPage";
import CountrySelector from "./components/CountrySelector";
import SecureRoute from "./routes/SecureRoute";
import Popup from "./components/Popup";
import {useRewardPopup} from "./hooks/useRewardPopup";
import ChangeCountry from "./components/ChangeCountry";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LoginPage from './pages/LoginPage';

const App: React.FC = () => {
    console.log('App рендерится');

    const {
        isRewardPopupVisible,
        popupRewards,
        handleClosePopup
    } = useRewardPopup()


    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: false,
            },
        },
    });


    return (
        <QueryClientProvider client={queryClient}>
            <GlobalStyles/>
            <AppContainer>
                <Routes>
                    <Route path="/login" element={<LoginPage/>}></Route>
                    <Route element={<SecureRoute/>}>
                        {/* <Route path="/" element={<Loader/>}/> */}
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/country-select" element={<CountrySelector/>}/>
                        <Route path="/ranks" element={<RanksPage/>}/>
                        <Route path="/aiGod" element={<AiGodPage/>}/>
                        <Route path="/tasks" element={<TasksPage/>}/>
                        <Route path="/leaderboard" element={<LeaderboardPage/>}/>
                        <Route path="/frens" element={<FrensPage/>}/>
                        <Route path="/change-country" element={<ChangeCountry/>}/>
                    </Route>
                    <Route path="*" element={<NotFoundPage/>}/>
                </Routes>

                {isRewardPopupVisible && (
                    <Popup
                        onClose={handleClosePopup}
                        rewards={popupRewards}  // Передаем сохраненные награды
                    />
                )}

            </AppContainer>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
    );
};

export default App;
