import React from 'react';
import styled from 'styled-components';
import MainButtonIcon from "../../assets/images/mane-button-icon.png";
import animation from "../../assets/animations/Wave_Coin_Effect_green_3.gif";
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';

interface LoaderProps {
    statusMessage: string;
}

const Loader: React.FC<LoaderProps> = ({ statusMessage }) => {
    const [initDataStatus, tokenStatus] = statusMessage.split('\n');

    return (
        <LoaderContainer>
            <ButtonContentWrapper>
                <CoinIcon src={MainButtonIcon}/>
                <ButtonBackgroundImage
                    src={animation}
                    alt="Animation"
                />
            </ButtonContentWrapper>
            <StatusContainer>
                <StatusItem>
                    <FaCheckCircle color="#4CAF50" size={20} />
                    <StatusText>{initDataStatus}</StatusText>
                </StatusItem>
                <StatusItem>
                    <FaExclamationCircle color="#FF9800" size={20} />
                    <StatusText>{tokenStatus}</StatusText>
                </StatusItem>
            </StatusContainer>
        </LoaderContainer>
    );
};

const ButtonBackgroundImage = styled.img`
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 1px solid #0A0A0A;
`;

const CoinIcon = styled.img`
    transition: transform 0.3s ease-in-out;
    position: absolute;
    z-index: 3;
    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;
    draggable: false;
    animation: upDown 1s ease-in-out infinite;

    @keyframes upDown {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
        100% {
            transform: translateY(0);
        }
    }
`;

const ButtonContentWrapper = styled.div`
    position: relative;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    border: none;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: inset 0 0 15px 15px #02080a;
        pointer-events: none;
        z-index: 1;
        border: none;
    }
`;

const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

const StatusContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StatusItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const StatusText = styled.p`
    margin-left: 10px;
    font-size: 16px;
    color: #333;
`;

export default Loader;
