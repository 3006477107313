import {create} from 'zustand';
import {getLeaderboard, getPastLeaderboard} from "../api/api";
import {getCurrentAndPreviousWeek} from "../utils/getCurrentWeekRange";

export interface LeaderboardUser {
    id: number;
    username: string;
    rank_name: string;
    rank_id: string;
    referral_earnings: number;
    position: number;
}

interface Season {
    date: string;
    id: string;
    title: string;
}

export interface LeaderboardResponse {
    leaders: LeaderboardUser[];
    total_count: number;
    user: LeaderboardUser;
}

interface LeaderboardState {
    currentSeasonIndex: number;
    currentUser: LeaderboardUser;
    leaderboard: LeaderboardUser[];
    seasons: Season[];
    totalCount: number;
    setCurrentSeason: (index: number) => void;
    setCurrentUser: (user: LeaderboardUser) => void;
    setLeaderboardData: (data: LeaderboardResponse) => void;
}

const {currentWeek, previousWeek} = getCurrentAndPreviousWeek();

export const useLeaderboardStore = create<LeaderboardState>((set) => ({
    currentSeasonIndex: 1,
    currentUser: {
        id: 0,
        username: '',
        rank_name: '',
        rank_id: '',
        referral_earnings: 0,
        position: 0,
    },
    leaderboard: [],
    seasons: [
        {id: '1', date: previousWeek, title: 'Previous Season '},
        {id: '2', date: currentWeek, title: 'Current Season '},
    ],
    totalCount: 0,
    setCurrentSeason: (index) => set({currentSeasonIndex: index}),
    setCurrentUser: (user) => set({currentUser: user}),
    setLeaderboardData: (data) => set({
        leaderboard: data.leaders,
        currentUser: data.user,
        totalCount: data.total_count
    }),
}));
