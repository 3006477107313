import Frens from "../components/Frens";
import Layout from "./Layout";

const FrensPage = () => {

    return (
        <>
            <Layout >
                <Frens/>
            </Layout>
        </>

    );
}


export default FrensPage;