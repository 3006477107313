import {create} from 'zustand';

import AcolyteIcon from '../assets/icons/acolyte.svg';
import DeaconIcon from '../assets/icons/deacon.svg';
import PriestIcon from '../assets/icons/priest.svg';
import BishopIcon from '../assets/icons/bishop.svg';
import ArchbishopIcon from '../assets/icons/archbishop.svg';
import {StoreState, UserRank} from "../types/uiStoreTypes";
import {useRankDisplayStore} from "./rankDisplayStore";
import { promoteRank } from '../api/api';


export const ranks: UserRank[] = [
    {
        id: 1,
        name: 'Initiate',
        levels: [
            {id: 1, price: 2640},
        ],
        image: AcolyteIcon
    },
    {
        id: 2,
        name: 'Brethren',
        levels: [
            {id: 2, price: 7920}
        ],
        image: DeaconIcon
    },
    {
        id: 3,
        name: 'Acolyte',
        levels: [
            {id: 3, price: 24200},
        ],
        image: PriestIcon
    },
    {
        id: 4,
        name: 'Curate',
        levels: [
            {id: 4, price: 60500},
            {id: 5, price: 145200},
        ],
        image: ArchbishopIcon
    },
    {
        id: 5,
        name: 'Pastor',
        levels: [
            {id: 6, price: 158400},
            {id: 7, price: 257400},
            {id: 8, price: 300300},
        ],
        image: BishopIcon
    },
    {
        id: 6,
        name: 'Arbites',
        levels: [
            {id: 9, price: 343200},
            {id: 10, price: 364650},
            {id: 11, price: 514800},
        ],
        image: ArchbishopIcon
    },
    {
        id: 7,
        name: 'Sacristan',
        levels: [
            {id: 12, price: 572000},
            {id: 13, price: 600600},
            {id: 14, price: 786500},
        ],
        image: ArchbishopIcon
    },
    {
        id: 8,
        name: 'Minister',
        levels: [
            {id: 15, price: 1029600},
            {id: 16, price: 1251250},
            {id: 17, price: 1673100},
        ],
        image: ArchbishopIcon
    },
    {
        id: 9,
        name: 'Deacon',
        levels: [
            {id: 18, price: 2002000},
            {id: 19, price: 2488200},
        ],
        image: ArchbishopIcon
    },
    {
        id: 10,
        name: 'Tribune',
        levels: [
            {id: 20, price: 0}, // Assuming 0 price for the highest rank
        ],
        image: ArchbishopIcon
    },
];


export const useUiStore = create<StoreState>((set, get) => ({
    id: 0,
    balance: 0,
    userName: '',
    currentRank: 3,
    currentLevel: 1,
    ranks,
    referralCode: '',
    avatar: '',
    coinsEarnedWeek: 0,
    country: '',
    regionChanges: 0,
    coins: 0,
    tokens: 0,
    questions: 0,

    swiperInstance: null,
    invitedFriends: 0,

    setSwiperInstance: (swiper) => set({swiperInstance: swiper}),

    setCurrentRank: (rankIndex) => set({currentRank: rankIndex}),

    setCurrentLevel: (level) => set({currentLevel: level}),

    increaseBalance: (amount) => set((state) => ({
        balance: state.balance + amount,
    })),

    upgradeRank: async () => {
        const { currentRank, currentLevel, balance, ranks, swiperInstance } = get();
        const rank = ranks[currentRank];
        const isLastLevel = currentLevel >= rank.levels.length;
        const isLastRank = currentRank >= ranks.length - 1;

        if (isLastRank && isLastLevel) {
            return; // Do nothing if at the highest rank and level
        }

        try {
            await promoteRank(); // Попытка промоутить ранк на сервере

            const newRank = isLastLevel ? currentRank + 1 : currentRank;
            const newLevel = isLastLevel ? 1 : currentLevel + 1;
            const newBalance = balance - rank.levels[currentLevel - 1].price;

            useRankDisplayStore.getState().setCurrentRankToDisplay(newRank);
            useRankDisplayStore.getState().setCurrentLevelToDisplay(newLevel);

            // Slide to the correct rank
            if (swiperInstance) {
                swiperInstance.slideTo(newRank);
            }

            set({
                currentRank: newRank,
                currentLevel: newLevel,
                balance: newBalance,
            });
        } catch (error) {
            // Ошибка уже обработана в promoteRank, ничего не делаем
        }
    },

    setUserName: (userName: string) => set({ userName }),

    setCoins: (coins: number) => set({ coins }),
    setTokens: (tokens: number) => set({ tokens }),
    setQuestions: (questions: number) => set({ questions }),

}));


