import {create} from 'zustand';

interface Fren {
    name: string;
    rank_name: string;
}

interface FrensStore {
    frensList: Fren[];
    referralLink: string;
    referralEarnings: number;
    setReferralInfo: (link: string, earnings: number, referals: Fren[]) => void;
    addFren: (fren: Fren) => void;
    removeFren: (index: number) => void;
    setFrensList: (frens: Fren[]) => void;
}

export const useFrensStore = create<FrensStore>((set) => ({
    frensList: [],
    referralLink: '',
    referralEarnings: 0,
    setReferralInfo: (link, earnings, referals) => set({
        referralLink: link,
        referralEarnings: earnings,
        frensList: referals
    }),
    setFrensList: (frens) => set({
        frensList: frens
    }),
    addFren: (fren) => set((state) => ({
        frensList: [...state.frensList, fren],
    })),
    removeFren: (index) => set((state) => ({
        frensList: state.frensList.filter((_, i) => i !== index),
    })),
}));
