import React, { useState } from 'react';
import styled from 'styled-components';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, eachDayOfInterval, isSameMonth, isSameDay } from 'date-fns';
import coinIcon from '../assets/images/coin-balance.svg';
import { colors } from '../styles/styles';
import MiningButton from './MiningButton';
import lineIcon from '../assets/icons/line.svg';
import Popup from './Popup';
import { useUiStore } from '../store/userStore';

interface RewardCalendarProps {
    onClose: () => void;
}

// Стили для контейнера календаря
const CalendarContainer = styled.div`
    background-color: ${colors.background};
    color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 41px;
    margin-top: 20px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

// Стили для иконки линии


// Стили для заголовка месяца
const MonthHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 18px;
    margin-bottom: 10px;
    font-family: 'K2D';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #808080;
`;

// Стили для кнопок переключения месяца
const MonthButton = styled.button`
    background: none;
    border: none;
    color: #FFD67E;
    font-size: 18px;
    cursor: pointer;
`;

// Стили для заголовка дней недели
const WeekHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: 'K2D';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #808080;
    margin-bottom: 10px;
`;

// Стили для ячейки заголовка дня недели
const WeekDayCell = styled.div`
    width: 40px;
    text-align: center;
    flex: 1;
`;

// Стили для контейнера дней
const DaysContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 2px;
    justify-items: center;
    width: 100%;
`;

// Стили для ячейки дня
const DayCell = styled.div<{ isActive: boolean; isClicked: boolean }>`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    background-color: ${({ isActive, isClicked }) => (isClicked ? 'rgba(255, 214, 126, 0.25)' : isActive && 'transparent')};
    border: ${({ isClicked, isActive }) => (isClicked ? '1px solid #FFD67E' : isActive ? '1px solid #FFD67E' : 'none')};
    background-color: ${({ isActive }) => (isActive ? 'transparent)' : 'FFD67E')};
    color: ${({ isActive }) => (isActive ? colors.activeText : '#ffffff')};
    border-radius: 8px;
    cursor: pointer;
    flex: 1;
    position: relative;

    ${({ isActive }) =>
        isActive &&
        `
        &::after {
            content: '';
            position: absolute;
            top: -15px;
            right: -15px;
            width: 22px;
            height: 22px;
            background-image: url(${coinIcon})  ;
            background-size: cover;
            z-index: 3;
        }
    `}
`;

const RewardCalendar: React.FC<RewardCalendarProps> = ({ onClose }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [clickedDates, setClickedDates] = useState<Date[]>([]);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [reward, setReward] = useState(0);

    const increaseBalance = useUiStore((state) => state.increaseBalance);

    const handlePrevMonth = () => {
        setCurrentDate(subMonths(currentDate, 1));
    };

    const handleNextMonth = () => {
        setCurrentDate(addMonths(currentDate, 1));
    };

    const handleDateClick = (day: Date) => {
        setClickedDates(prev => {
            if (prev.some(clickedDay => isSameDay(clickedDay, day))) {
                return prev.filter(clickedDay => !isSameDay(clickedDay, day));
            } else {
                return [...prev, day];
            }
        });
    };

    const handleClaimClick = () => {
        setReward(31343);
        setPopupVisible(true);
    };

    const handlePopupClose = () => {
        increaseBalance(reward);
        setPopupVisible(false);
        onClose();
    };

    const renderDays = () => {
        const startDate = startOfWeek(startOfMonth(currentDate));
        const endDate = endOfWeek(endOfMonth(currentDate));

        const days = eachDayOfInterval({ start: startDate, end: endDate });

        return days.map(day => {
            const isActive = isSameDay(day, new Date());
            const isCurrentMonth = isSameMonth(day, currentDate);
            const isClicked = clickedDates.some(clickedDay => isSameDay(clickedDay, day));

            return (
                <DayCell
                    key={day.toString()}
                    isActive={isActive}
                    isClicked={isClicked}
                    style={{ opacity: isCurrentMonth ? 1 : 0.5 }}
                    onClick={() => handleDateClick(day)}
                >
                    {format(day, 'd')}
                </DayCell>
            );
        });
    };

    return (
        <CalendarContainer>
            <LineIcon />
            <CalendarTitle>Daily Bonus Calendar</CalendarTitle>
            <MonthHeader>
                <MonthButton onClick={handlePrevMonth}>&lt;</MonthButton>
                <div>{format(currentDate, 'MMMM yyyy')}</div>
                <MonthButton onClick={handleNextMonth}>&gt;</MonthButton>
            </MonthHeader>
            <WeekHeader>
                {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
                    <WeekDayCell key={day}>{day}</WeekDayCell>
                ))}
            </WeekHeader>
            <DaysContainer>
                {renderDays()}
            </DaysContainer>
            <MiningButton onClick={handleClaimClick} />
            {isPopupVisible && (
                <Popup
                    onClose={handlePopupClose}
                    rewards={[{ id: 0, type: 'DAILY_BONUS', coins: reward, create_time: new Date().toISOString(), is_activated: true }]}
                />
            )}
        </CalendarContainer>
    );
};

const CalendarTitle = styled.h2`
    font-family: 'K2D';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #FFD67E;
    margin-bottom: 20px;
    text-transform: uppercase;
`;

const LineIcon = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 40px;
    background-image: url(${lineIcon});
    background-size: contain;
    background-repeat: no-repeat;
`;

export default RewardCalendar; 