import Lieaderboard from "../components/Lieaderboard";
import Layout from "./Layout";

const TasksPage = () => {

    return (
        <>
            <Layout >
                <Lieaderboard/>
            </Layout>
        </>

    );
}




export default TasksPage;