import TaskList from "../components/TaskList";
import Layout from "./Layout";

const TasksPage = () => {
    return (
        <Layout>
            <TaskList/>
        </Layout>
    );
}


export default TasksPage;