import React, {useEffect, useState} from 'react';
import {useUiStore} from "../store/userStore";
import {useMiningStore} from "../store/miningStore";
import {colors, CtaButton} from "../styles/styles";
import MiningCoinIcon from '../assets/images/mining-coin-balance.svg';
import styled from "styled-components";
import CoinIconBlack from "../assets/images/coin-icon-black.svg";
import TimerIcon from "../assets/images/timer-icon.svg";
import ButtonFigure from "../assets/images/buttonFigure.svg";
import Popup from "./Popup";
import {claimMining, startMining} from "../api/api";
import WarningMessage from "../store/WarningMessage";

interface MiningButtonProps {
    onClick?: () => void;
}

const MiningButton: React.FC<MiningButtonProps> = ({ onClick }) => {
    const [isTimeUp, setIsTimeUp] = useState(false);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [isRankWarningVisible, setRankWarningVisible] = useState(false);


    const {miningReward, setMiningReward, setNextMiningDate, startMiningStore} = useMiningStore();


    const increaseBalance = useUiStore((state) => state.increaseBalance)
    const currentRank = useUiStore((state) => state.currentRank)

    const timeLeft = useMiningStore((state) => state.timeLeft)
    const isMining = useMiningStore((state) => state.isMining)
    const nextMiningDate = useMiningStore((state) => state.nextMiningDate)
    const stopMining = useMiningStore((state) => state.stopMining)


    useEffect(() => {
        if (timeLeft === 0) {
            setIsTimeUp(true);
        }
    }, [timeLeft, isMining]);


    const formatTime = (time: number) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleStartMining = async () => {
        if (currentRank < 3) {
            setRankWarningVisible(true);
            setTimeout(() => setRankWarningVisible(false), 3000);  // Скрыть через 3 секунды
        } else {
            try {
                const miningResponse = await startMining()

                setMiningReward(miningResponse.data.max_extraction)
                setNextMiningDate(new Date(miningResponse.data.next_mining_dt))
                startMiningStore()
            } catch (error) {
                console.log(error)
            }
        }

    };
    const handleClaimMining = async () => {
        increaseBalance(31343)


            // try {
            //     const miningResponse = await claimMining()

            //     setMiningReward(miningResponse.data.max_extraction)
            //     setIsTimeUp(false)
            //     setPopupVisible(true);
            //     stopMining()
            // } catch (error) {
            //     console.log(error)
            // }

    };


    return (
        <MiningButtonContainer>
            <ButtonWrapper isDisabled={currentRank < 3} onClick={onClick}>
                <ButtonContent>
                    <Wrapper>
                        <Icon src={CoinIconBlack} alt="Coin Icon"/>
                        <ButtonPrice>31.343</ButtonPrice>
                    </Wrapper>
                    <CentralIcon src={ButtonFigure} alt=""/>
                    <ButtonTitle>
                        Claim
                    </ButtonTitle>
                </ButtonContent>
            </ButtonWrapper>
            {/* {isPopupVisible && <Popup
                onClose={() => setPopupVisible(false)}
                rewards={[{id: 0, type: 'mining', coins: miningReward}]}
            />} */}
            {isRankWarningVisible && (
                <WarningMessage isVisible={isRankWarningVisible} message={'Available at Rank Archdeacon'}/>
            )}



        </MiningButtonContainer>
    );
};

const MiningButtonContainer = styled(CtaButton)`
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 40px;
`;
const ButtonWrapper = styled.div<{ isDisabled: boolean }>`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    filter: ${({ isDisabled }) => (isDisabled ? 'brightness(0.5)' : 'brightness(1)')};
`;


const MiningCoin = styled.img`
    margin-right: 5px;
`;
const StartMiningButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    border: none;
    outline: none;
    border-radius: inherit;
    text-transform: uppercase;
    color: black;
`;

const ButtonContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 15px;
`;

const TimerWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: flex-end;

`;
const Wrapper = styled.div`
    display: flex;
    font-size: 14px;
    width: 50%;
    align-items: center;
`;
const ButtonPrice = styled.div`
`;
const ButtonTitle = styled.div`
    display: flex;
    font-size: 14px;
    height: 100%;
    margin-left: auto;
    align-items: center;
    width: 50%;
    justify-content: end;
`;
const Icon = styled.img`
    margin-right: 10px;
`;
const CentralIcon = styled.img`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    @media (max-height: 750px) {
        height: 4vh;
    }
`;



export default MiningButton;
