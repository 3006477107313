import {create} from 'zustand';
import {toast} from 'react-toastify';
import {completeTask, getTasks, startTaskApi} from "../api/api";
import {Reward} from "./rewardStore";

interface TaskStore {
    tasks: Task[];
    loading: boolean;
    error: string | null;
    fetchTasks: () => Promise<void>;
    completeTask: (id: number) => void;
    startTask: (id: number) => void;
    setClaimedStatus: (taskId: number) => void;
    setTasks: (tasks: Task[]) => void;
    checkStatus: () => void;
}

export interface Task {
    id: number;
    status: 'not_started' | 'pending' | 'in_progress' | 'completed' | 'claimed';
    reward: Reward;
    description: string;
    condition: {
        url: string;
    }
    condition_type: string;
    is_started: boolean;

}


export const useTaskStore = create<TaskStore>((set, get) => ({
    tasks: [],
    loading: false,
    error: null,

    // Асинхронная функция для загрузки данных
    fetchTasks: async () => {
        set({loading: true, error: null});
        try {
            const tasks: Task[] = await getTasks(); // Ваш API для получения задач
            console.log(tasks)

            // Добавляем статус к каждой задаче
            const determineTaskStatus = (task: Task): Task['status'] => {
                return task.is_started ? 'completed' : 'not_started';
            };

            const tasksWithStatus = tasks.map((task: Task) => ({
                ...task,
                status: determineTaskStatus(task)
            }));

            set({tasks: tasksWithStatus, loading: false});
        } catch (error) {
            set({error: 'Failed to load tasks', loading: false});
            toast.error('Failed to load tasks');
        }
    },


    checkStatus: () => {


    },

    startTask: async (taskId: number) => {
        const task = get().tasks.find((task) => task.id === taskId);
        if (task && task.status === 'not_started') {
            set((state) => ({
                tasks: state.tasks.map((t) =>
                    t.id === taskId ? {...t, status: 'pending'} : t
                ),
            }));

            // Если задача имеет тип 'visit_link', открываем ссылку
            if (task.condition_type === 'visit_link') {
                window.open(task.condition.url, '_blank');
            }

            try {
                await startTaskApi(taskId);
                // Если задача успешно началась, через 10 секунд выполняем её
                setTimeout(async () => {
                    set((state) => ({
                        tasks: state.tasks.map((t) =>
                            t.id === taskId ? {...t, status: 'completed'} : t
                        ),
                    }));
                }, 10000);
            } catch (error) {
                set({error: 'Failed to start task'});
                toast.error('Failed to start task');
            }
        }
    },

    completeTask: (taskId: number) => {
        set((state) => ({
            tasks: state.tasks.map((task) =>
                task.id === taskId ? {...task, status: 'completed'} : task
            ),
        }));
    },

    setClaimedStatus: (taskId: number) => {
        set((state) => ({
            tasks: state.tasks.map((task) =>
                task.id === taskId ? {...task, status: 'claimed'} : task
            ),
        }));
    },

    setTasks: (tasks: Task[]) => set({tasks}),
}));
