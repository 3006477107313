import React, {useEffect, useRef, useState} from 'react';
import Select from 'react-select'; // Добавьте StylesConfig, если вам нужно стилизовать селект
import {useCountryStore} from '../store/countryStore';
import {styled} from "styled-components";
import {Link, useNavigate} from "react-router-dom";
import {colors, CtaButton} from "../styles/styles";
import {changeRegion} from "../api/api";
import {useUiStore} from "../store/userStore";

const Eden: React.FC = () => {
    const navigate = useNavigate();
    const selectRef = useRef<any>(null);
    const inputRef = useRef<HTMLInputElement>(null);


    const {country, countries, fetchCountries, setCountry} = useCountryStore();
    const currentCountry = useUiStore(state => state.country)
    const regionChanges = useUiStore(state => state.regionChanges)
    const isRegionChangeLimitReached = regionChanges >= 2;

    useEffect(() => {
        fetchCountries(); // Вызываем фетч данных при монтировании компонента
    }, [fetchCountries]);


    const handleAcceptCountry = () => {
        if(country){
            changeRegion(country.value)
        }
        navigate('/')
    }

    return (
        <Container>
            <Title>Change Region</Title>

            <Subtitle>
                Changing the region allows you to participate in the leaderboard for the selected region.
                {
                    isRegionChangeLimitReached ?
                        ' At the moment, you cannot change the region because you have already changed it.'
                        : ' At the moment, you can only change the region once if you specified it accidentally during registration.'
                }

            </Subtitle>
            <CountrySelectWrapper>



                {!isRegionChangeLimitReached && <Select isDisabled={isRegionChangeLimitReached}
                        ref={selectRef}
                        menuPlacement="top"
                        options={countries}
                        value={country}
                        onChange={(selectedOption: any) => setCountry(selectedOption)}
                        placeholder="Select your country"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: '#ddd',
                                primary: '#fff',
                                neutral0: '#000',
                                neutral80: '#fff',
                            },
                        })}
                />}
            </CountrySelectWrapper>
            {
                isRegionChangeLimitReached ? (
                    <Button onClick={() => navigate('/')}>CANCEL</Button>

                ) : (
                    <Button disabled={isRegionChangeLimitReached} onClick={() => handleAcceptCountry()}>CHANGE REGION</Button>
                )
            }

        </Container>
    );
};


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: ${colors.background};
    color: #fff;
    padding: 2.484vh;
    box-sizing: border-box;
    text-align: center;
`;

export const Title = styled.h1`
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.739vh;
    line-height: 1.863vh;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 5vh;
`;

export const Subtitle = styled.p`
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 1.739vh;
    color: #FFFFFF;
`;

export const CountrySelectWrapper = styled.div`
    width: 100%;
    max-width: 49.689vh;
    margin-bottom: 2.484vh;
`;

export const Button = styled(CtaButton)`
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    max-width: 49.689vh;
    font-size: 1.988vh;
    font-weight: bold;

    &:hover {
        background-color: #ddd;
    }
`;

export default Eden;
