import React from 'react';
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import styled from "styled-components";

const NotFoundPage = () => {
    return (
        <>
            <NotFoundText>Page not found go <StyledLink to={'/'}>Home</StyledLink></NotFoundText>
            <Footer/>
        </>
    );
};

const NotFoundText = styled.h1`
  color: #ffffff;
`
const StyledLink = styled(Link)`
  color: #4cd964;
`


export default NotFoundPage;