import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {colors} from "../styles/styles";

interface WarningMessageProps {
    isVisible: boolean;
    message: string;
}

const WarningMessage: React.FC<WarningMessageProps> = ({ isVisible, message }) => {
    return (
        <WarningMessageContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: isVisible ? 1 : 0 }}
            transition={{ duration: 0.5 }}
        >
            {message}
        </WarningMessageContainer>
    );
};

const WarningMessageContainer = styled(motion.div)`
    position: absolute;
    top: -50px;
    background-color: ${colors.background};
    color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 14px;
    opacity: 1;
    width: 100%;
    z-index: 100;
    border: 1px solid rgba(105, 255, 147, 0.5);
`;

export default WarningMessage;