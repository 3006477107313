import React from 'react';
import styled, {keyframes} from 'styled-components';
import {colors} from "../styles/styles";
import ClosePopupIcon from "../assets/images/close-popup-icon.svg"

interface BoostPopupProps {
    onClose: () => void;
    children: any
}

const SmallPopup: React.FC<BoostPopupProps> = ({ onClose, children}) => {

    return (
        <PopupWrapper isVisible={'s'}>
            <div>123</div>
            {children}
            <ClosePopup src={ClosePopupIcon} onClick={onClose}/>
        </PopupWrapper>
    );
};


const PopupWrapper = styled.div<{ isVisible: string }>`
    position: fixed;
    bottom: calc(62px + 1vh);
    left: 10px;
    right: 0;
    height: 250px;
    width: calc(100% - 20px);
    border: 1px solid rgba(105, 255, 147, 0.5);
    border-radius: 8px;
    background-color: ${colors.background};
    padding: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    z-index: 10;
    gap: 10px;
    animation: ${props => props.isVisible === null ? slideOut : slideIn} 0.3s ease-out;
`;

const ClosePopup = styled.img`
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
`;


const slideIn = keyframes`
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const slideOut = keyframes`
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(100%);
        opacity: 0;
    }
`;

export default SmallPopup;
