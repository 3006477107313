import React, {useEffect} from 'react';
import Header from "../components/Header";
import {Container} from "../styles/styles";
import ControlPanel from "../components/ControlPanel";
import Avatar from "../components/Avatar";
import RankList from "../components/RankList";
import Stats from "../components/Stats";
import Footer from "../components/Footer";
import Layout from "./Layout";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RanksPage = () => {


    return (
        <>
            <Layout>
                <RankList/>
                <Stats/>
            </Layout>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>

    );
};

export default RanksPage;