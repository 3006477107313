// Start of Selection
import { create } from 'zustand';
import { claimReward, getRewardsList } from "../api/api";
import { useUiStore } from './userStore'; // Импортируем useUiStore

// Определяем интерфейс награды
export interface Reward {
    id: number;
    type: 'DAILY_BONUS' | 'ai_question' | 'leaderboard' | 'mining' | 'task' | 'launches_series' | 'invite_friends';
    coins: number;
    create_time: string;
    is_activated: boolean;
    questions?: number;
    tokens?: number;
    
}

// Определяем интерфейс состояния Store
interface RewardStoreState {
    rewards: Reward[]; // Все награды
    rewardsToClaim: Reward[]; // Награды, которые нужно заклеймить
    balance: number; // Добавляем состояние для баланса
    setRewards: (rewards: Reward[]) => void; // Устанавливаем список всех наград
    setRewardsToClaim: (rewards: Reward[]) => void; // Устанавливаем список наград для клейминга
    claimRewards: () => Promise<void>; // Клейминг наград
}

// Создаем Zustand store
export const useRewardStore = create<RewardStoreState>((set, get) => ({
    rewards: [], // Изначальное состояние для списка наград
    rewardsToClaim: [], // Изначальное состояние для списка наград к клеймингу
    balance: 0, // Изначальное значение баланса

    // Устанавливаем список всех наград
    setRewards: (rewards: Reward[]) => set({ rewards }),

    // Устанавливаем список наград, которые нужно заклеймить
    setRewardsToClaim: (rewardsToClaim: Reward[]) => set({ rewardsToClaim }),

    // Функция для клейминга наград
    claimRewards: async () => {
        const { rewards, rewardsToClaim } = get();
        const dailyRewards = rewards.filter(reward => reward.type === 'DAILY_BONUS' && !reward.is_activated);

        if (dailyRewards.length > 0) {
            const rewardIds = dailyRewards.map(reward => reward.id);
            await claimReward(rewardIds);
            console.log(`Награды с ID ${rewardIds.join(', ')} успешно заклеймлены`);

            const totalCoins = dailyRewards.reduce((sum, reward) => sum + reward.coins, 0);
            const claimableCoins = rewardsToClaim.reduce((sum, reward) => sum + reward.coins, 0);
            const updatedRewards = rewards.filter(reward => !dailyRewards.includes(reward));

            // Используем increaseBalance для обновления баланса
            useUiStore.getState().increaseBalance(totalCoins + claimableCoins);

            set({ rewards: updatedRewards });
        }
    }
}));
