export function getWeekRange(date: Date): string {
    const dayOfWeek = date.getDay();

    // Находим понедельник недели, к которой относится переданная дата
    const monday = new Date(date);
    const daysToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Если воскресенье, смещаемся на -6 дней
    monday.setDate(date.getDate() + daysToMonday);

    // Находим воскресенье той же недели
    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);

    // Форматируем дату в формате "01 Jul."
    const formatDate = (date: Date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('en-US', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month}. ${year}`;
    };

    return `${formatDate(monday)} - ${formatDate(sunday)}`;
}

export function getCurrentAndPreviousWeek(): { currentWeek: string, previousWeek: string } {
    const now = new Date();

    // Текущая неделя
    const currentWeek = getWeekRange(now);

    // Предыдущая неделя (отнимаем 7 дней)
    const previousDate = new Date(now);
    previousDate.setDate(now.getDate() - 7);
    const previousWeek = getWeekRange(previousDate);

    return { currentWeek, previousWeek };
}

// Пример использования:
const { currentWeek, previousWeek } = getCurrentAndPreviousWeek();
console.log('Текущая неделя:', currentWeek);
console.log('Предыдущая неделя:', previousWeek);
