import { toast } from 'react-toastify';
import axios from 'axios';

// Функция для обработки ошибок Axios
const getAxiosErrorMessage = (error: unknown): string => {
    if (!axios.isAxiosError(error) || !error.response) {
        return 'No response from server';
    }

    const { status, data } = error.response;

    if (status === 409) {
        return data.message?.text || 'The requested change cannot be made due to a conflict.';
    }

    return 'Server error';
};

// Вспомогательная функция для логирования ошибки
const logError = (error: unknown) => {
    if (axios.isAxiosError(error)) {
        console.log('Axios error:', error.message);
    } else if (error instanceof Error) {
        console.log('Error:', error.message);
    }
}

// Функция для обработки ошибок
export const handleApiError = (error: unknown): string => {
    logError(error);

    let errorMessage = 'An unexpected error occurred.';

    if (axios.isAxiosError(error)) {
        errorMessage = getAxiosErrorMessage(error);
    } else if (error instanceof Error) {
        errorMessage = error.message;
    }

    toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    return errorMessage;
};