import React, {useState} from 'react';
import styled from 'styled-components';
import Layout from "./Layout";
import priestChat from '../assets/images/priest-chat.png';
import RewardCalendar from '../components/RewardCalendar';


// Стили для контейнера
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;



// Стили для заголовка календаря


// Стили для изображения
const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 64%;
    background-image: url(${priestChat});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
`;

// Стили для кнопки
const CTAButton = styled.button`
    width: 200px;
    height: 50px;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
    cursor: pointer;
`;

const HomePage = () => {
    const [isCalendarVisible, setIsCalendarVisible] = useState(true);

    const handleCalendarClose = () => {
        setIsCalendarVisible(false);
    };

    return (
        <Layout hideAvatar={isCalendarVisible}>
            <Container>
                {!isCalendarVisible && (
                    <ImageWrapper>
                        <CTAButton onClick={() => setIsCalendarVisible(true)}>Contact AI Priest</CTAButton>
                    </ImageWrapper>
                )}
                {isCalendarVisible && (
                    <CalendarContainer>
                        <RewardCalendar onClose={handleCalendarClose} />
                    </CalendarContainer>
                )}
            </Container>
        </Layout>
    );
};



const CalendarContainer = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    width: 100%;
    height: 100%;
`;

export default HomePage;