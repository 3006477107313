import React from 'react';
import styled  from 'styled-components';

import borderSVG from '../assets/images/avatar-frame.svg'
import {colors} from "../styles/styles";
import {useUiStore} from "../store/userStore";
import defaultAvatar from '../assets/images/default-avatar.png';

const Avatar: React.FC = () => {

    const userName = useUiStore((state) => state.userName);
    const avatar = useUiStore((state) => state.avatar) || defaultAvatar;
    const currentRank = useUiStore((state) => state.currentRank);
    const ranks = useUiStore((state) => state.ranks);
    const rank = ranks[currentRank]




    return (
        <>
            <AvatarContainer>
                <AvatarFrame>
                    <AvatarImage src={avatar} alt="Avatar"/>
                    {/*<SvgAvatar username={userName} />*/}
                    <UserNameContainer>{userName}</UserNameContainer>
                </AvatarFrame>
                <RankContainer>Rank: «{rank.name}»</RankContainer>
                <RankImage src={rank.image}/>
            </AvatarContainer>
        </>
    );
};


const AvatarContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 5;
    pointer-events: none; // Не мешает взаимодействию с изображением
    user-select: none;
    //height: 25vh;
    flex: 0 0 27.5%;
    position: relative;
`;
const AvatarFrame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22vh;
    height: 15.867vh;
    background-image: url(${borderSVG});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    position: relative;
    overflow: visible;
`;


const AvatarImage = styled.img`
    border-radius: 50%; /* Делает изображение круглым */
    transform: translateY(1vh);
    width: 57%;
`;

const RankImage = styled.img`
    width: 5.111vh;
    margin-top: 10px;
    //height: 60px;
`;

const UserNameContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -2vh;
    width: 14.907vh;
    height: 3.975vh;
    background: ${colors.background};
    border: 1px solid #694407;
    border-radius: 100px;
    color: ${colors.primaryText};
    font-weight: 700;
    font-size: 1.7vh;
`;

const RankContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.secondaryText};
    font-size: 10px;
    font-weight: lighter;
    margin-top: 3vh;
`;


export default Avatar;