import {create} from 'zustand';

// Типы для состояния
interface MiningState {
    nextMiningDate: Date;  // Дата следующего майнинга
    timeLeft: number;  // Оставшееся время в секундах
    isMining: boolean;  // Таймер активен
    miningReward: number;
    startMiningStore: () => void;  // Запуск таймера майнинга
    setNextMiningDate: (nextMiningDate: Date) => void;  // Запуск таймера майнинга
    stopMining: () => void;  // Остановка таймера
    updateTimer: () => void;  // Обновление таймера
    setMiningReward: (reward: number) => void;  // Обновление таймера
    setIsMining: (state: boolean) => void;  // Обновление таймера
}

// Функция для расчета разницы в секундах между двумя датами
const calculateTimeLeft = (nextMiningDate: Date, currentDate: Date): number => {
    const diffInMs = nextMiningDate.getTime() - currentDate.getTime();
    return Math.floor(diffInMs / 1000);  // Преобразуем миллисекунды в секунды
};

// Создаем Zustand store для майнинга
export const useMiningStore = create<MiningState>((set, get) => ({
    nextMiningDate: new Date(Date.now() + 8 * 60 * 60 * 1000),
    timeLeft: 8 * 60 * 60,
    isMining: false,
    miningReward: 0,

    // Функция для запуска таймера майнинга
    startMiningStore: () => {

        set({ isMining: true});

        // Устанавливаем интервал для обновления таймера каждую секунду
        const timerInterval = setInterval(() => {
            const {nextMiningDate, updateTimer} = get();

            if (nextMiningDate) {
                const timeLeft = calculateTimeLeft(nextMiningDate, new Date());
                if (timeLeft > 0) {
                    updateTimer();
                } else {
                    clearInterval(timerInterval);  // Останавливаем таймер, если время истекло
                    set({timeLeft: 0});  // Останавливаем майнинг
                }
            }
        }, 1000);
    },

    // Обновление текущего времени и пересчет оставшегося времени
    updateTimer: () => {
        set((state) => ({
            timeLeft: calculateTimeLeft(state.nextMiningDate as Date, new Date()),
        }));
    },

    // Функция для остановки майнинга
    stopMining: () => {
        set({isMining: false, timeLeft: 0});
    },    // Функция для остановки майнинга
    setMiningReward: (miningReward) => {
        set({miningReward});
    },
    setNextMiningDate: (nextMiningDate) => {
        set({nextMiningDate});
    },
    setIsMining: (state) => {
        set({isMining: state});
    },

}));
