import React from 'react';
import ConfettiAnimation from './ConfettiAnimation';
import styled from 'styled-components';
import {colors, CtaButton} from '../styles/styles';
import {Reward} from "../store/rewardStore";
import coinIcon from "../assets/images/coin-icon-green.svg"
import {motion} from "framer-motion";
import { useUiStore } from '../store/userStore'; // Импортируем useUiStore

interface PopupProps {
    onClose: () => void;
    header?: string; // Заголовок (опциональный)
    subTitle?: string; // Подзаголовок (опциональный)
    rewards?: Reward[]; // Значение награды (опциональное)
    userStats?: { // Сделаем userStats опциональным
        coins: number;
        tokens: number;
        questions: number;
    } | null;
    onClaim?: () => void; // Добавляем onClaim как опциональное свойство
}

const Popup: React.FC<PopupProps> = ({
    onClose,
    header = "Congratulation!!",
    subTitle = "You've earned!",
    rewards = [],
    userStats,
    onClaim, // Используем onClaim
}) => {
    const totalCoins = rewards.reduce((sum, reward) => sum + reward.coins, 0);
    const increaseBalance = useUiStore((state) => state.increaseBalance); // Получаем функцию increaseBalance

    const handleOkClick = () => {
        if (totalCoins > 0) {
            increaseBalance(totalCoins); // Увеличиваем баланс на количество монет
        }
        onClose(); // Закрываем попап
    };

    return (
        <PopupWrapper
            className="reward-popup"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                duration: 0.2,
                ease: "easeInOut"
            }}
        >
            <ConfettiAnimation />
            <PopupHeader>{header}</PopupHeader>
            <PopupSubTitle>{subTitle}</PopupSubTitle>
            {totalCoins > 0 && (
                <RewardDescription>
                    <Icon src={coinIcon} />
                    Coins: {totalCoins}
                </RewardDescription>
            )}
            {userStats && (
                <div>
                    <h3>User Stats</h3>
                    <p>Coins: {userStats.coins}</p>
                    <p>Tokens: {userStats.tokens}</p>
                    <p>Questions: {userStats.questions}</p>
                </div>
            )}
            {onClaim && <button onClick={onClaim}>Claim</button>} {/* Добавляем кнопку Claim */}
            <ClosePopupButton onClick={handleOkClick}>OK</ClosePopupButton> {/* Используем handleOkClick */}
        </PopupWrapper>
    );
};

const PopupWrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 20px);
    border: 1px solid ${colors.mainColor};
    align-items: center;
    position: fixed;
    bottom: 8.2vh;
    left: 50%;
    height: 86%;
    transform: translate(-50%, 0);
    background: white;
    padding: 20px;
    border-radius: 8px;
    z-index: 1000;
    background: radial-gradient(163.59% 90.02% at 50% 0%, rgba(68, 94, 76, 0.8) 0%, rgba(9, 9, 17, 0.8) 77.42%);
    box-shadow: inset 0px 0px 130px #090911;
    backdrop-filter: blur(4px);

`;

const RewardDescription = styled.div`
    font-size: 14px;
    color: #FFFFFF;
    margin-bottom: 1.5vh;
    display: flex;
    align-items: center;
`;

const PopupHeader = styled.h1`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-transform: uppercase;
    color: #FFFFFF;
    z-index: 10001;
    margin-bottom: 15px;
`;

const PopupSubTitle = styled.h2`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #A7A7A7;
    margin-bottom: 50px;
`;

const RewardTitle = styled.h2`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 2vh;
`;

const Icon = styled.img`
    margin-right: 1vh;
`;
const RewardItem = styled.div`
    display: flex;
    flex-direction: column;
`;
const ClosePopupButton = styled(CtaButton)`
    width: 100px;
    height: 32px;
`;

export default Popup;
