import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Task, useTaskStore} from '../store/tasksStore';
import {colors, CtaButton} from "../styles/styles";
import coinIcon from "../assets/images/coin-icon-green.svg";
import Spinner from "./helpers/Spinner";
import {completeTask} from "../api/api";
import Popup from "./Popup";
import {motion} from "framer-motion";
import { useUiStore } from '../store/userStore'; // Импортируем useUiStore

const TaskList: React.FC = () => {
    const {tasks, fetchTasks, startTask, setClaimedStatus, loading, error} = useTaskStore();
    const { setCoins, setTokens, setQuestions } = useUiStore(); // Получаем сеттеры из userStore
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [selectedTask, setSelectedTask] = useState<Task | null>(null);

    useEffect(() => {
        // Загружаем задачи при монтировании компонента
        fetchTasks();
    }, [fetchTasks]);

    const handleTaskClick = async (taskId: number) => {
        const task = tasks.find((task) => task.id === taskId);
        if (task && task.status === 'not_started') {
            await startTask(taskId);
        }
    };

    const handleClaimClick = async (taskId: number) => {
        const task = tasks.find((task) => task.id === taskId);
        if (task) {
            const { user_stats } = await completeTask(taskId);
            setCoins(user_stats.coins);
            setTokens(user_stats.tokens);
            setQuestions(user_stats.questions);
            setClaimedStatus(taskId);
            setSelectedTask(task);
            setPopupVisible(true);
        }
    };

    const getButtonText = (status: string) => {
        switch (status) {
            case 'claimed':
                return 'Claimed';
            case 'completed':
                return 'Claim';
            case 'pending':
                return <Spinner/>;
            default:
                return 'Start';
        }
    };

    if (error) {
        return <div>Error: {error}</div>;  // Показать сообщение об ошибке
    }

    return (
        <TaskListContainer>
            {loading ? (
                <>
                </>
            ) : (
                <>
                    {tasks.length === 0 ? ( // Проверяем, есть ли задачи
                        <NoTasksMessage>No available tasks yet.</NoTasksMessage> // Сообщение, если задач нет
                    ) : (
                        tasks.map((task) => (
                            <TaskContainer
                                initial={{ x: '-100vw' }} // Начало снаружи слева
                                animate={{ x: 0 }} // Появляется в центре
                                exit={{ x: '100vw' }} // Исчезает вправо
                                transition={{ duration: 0.1 }}
                                key={task.id}>
                                <RewardInfo >
                                    {task.reward.coins > 0 &&
                                        <Reward>+{task.reward.coins }<RewardIcon src={coinIcon}/> </Reward>}
                                </RewardInfo>
                                <TaskDescription>{task.description}</TaskDescription>
                                <ActionButton
                                    onClick={() => {
                                        if (task.status === 'completed') {
                                            handleClaimClick(task.id);
                                        } else {
                                            handleTaskClick(task.id);
                                        }
                                    }}
                                    isClaimable={task.status === 'completed'}
                                >
                                    {getButtonText(task.status)}
                                </ActionButton>
                            </TaskContainer>
                        ))
                    )}
                </>
            )}
            {isPopupVisible && selectedTask && (  // Передаем выбранную задачу в попап
                <Popup
                    onClose={() => setPopupVisible(false)}
                    rewards={[selectedTask.reward]}  // Передаем награду выбранной задачи
                />
            )}
        </TaskListContainer>
    );
};

// Стили для компонентов (оставьте без изменений)
const NoTasksMessage = styled.div`
    color: ${colors.mainColor}; // Цвет текста сообщения
    font-size: 1.5em; // Размер шрифта
    text-align: center; // Центрируем текст
    margin-top: 20px; // Отступ сверху
    padding: 20px; // Внутренние отступы
`;

const RewardInfo = styled.div`
        color: ${colors.mainColor};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
`;
const Reward = styled.div`
    color: ${colors.mainColor};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.491vh;
    gap: 0.5vh;
`;

const TaskListContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    margin-top: 9vh;
    overflow-y: auto;
    flex-grow: 1;
`;

const TaskContainer = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 80px;
    background-color: #0A0A0A;
    border-bottom: 1px solid #27272F;
`;

const RewardIcon = styled.img``;

const TaskDescription = styled.div`
    font-size: 12px;
    line-height: 140%;
    display: flex;
    align-items: center;
    width: 50%;
    color: #FFFFFF;
`;

const ActionButton = styled(CtaButton)<{ isClaimable: boolean }>`
    width: 12.422vh;
    background-color: ${({isClaimable}) => (isClaimable ? colors.mainColor : '#fff')};
`;

export default TaskList;
